<template>
  <div class="">
    Cabinet
  </div>
</template>

<script>
export default {
  name: 'Cabinet',
  mounted: function () {
  }
}
</script>
